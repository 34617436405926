import React from "react"

// Components
import Container from "./Container"
import Flex from "./Flex"
import Heading from "./Heading"
import Text from "./Text"
import StyledLink from "./StyledLink"

import {
  FaTwitter
} from 'react-icons/fa'

import { StaticImage } from "gatsby-plugin-image"

const Team = props =>

<Flex
  py={[6, 9]}
  bg="gray3"
  width={1}
  flexDirection="column"
  id="team"
>
  <Container>
    <Heading
      as="h2"
      mb={6}
      fontSize={[4, 5]}
      color="white"
    > FootFellaz Team </Heading>

    <Text
      mb={8}
      fontSize={[2, 3]}
      color="white"
    >
      We are a group of friends who are passionate about Football and NFT’s. Deciding to combine the two, we came up with FootFellaz; a season long competition where your NFT earns you money based on real world team and player performance. Respectively we are supporters of SV Werder Bremen, Bayern Munchen, and West Bromwich Albion. And yes, at one point or another we were all addicted to opening Ultimate Team packs. 
    </Text>

    <Flex
      flexWrap="wrap"
      justifyContent="center"
    >

      <Flex
        mb={6}
        pr={[0, 0, 4]}
        flexDirection="column"
        alignItems={["center", "center", "flex-start"]}
        width={[1, 0.5, "calc(100% / 3)"]}
      >
        <StaticImage
          src="../images/odmln.png"
          alt="odmln"
          style={{
            maxWidth: '200px',
            clipPath: 'circle(50%)'
          }}
        />
        <Text
          mt={5}
          fontSize={[2, 3]}
          fontWeight="bold"
        > odmln </Text>
        <Text
          mb={4}
          fontSize={[1, 2]}
          color="white"
        >
          Design & Dev Lead
        </Text>
        <StyledLink
          href="https://twitter.com/odmln"
          target="_blank"
          title="Twitter"
          rel="noopener noreferrer"
        >
          <Flex
            height="32px"
            width="32px"
            alignItems="center"
            justifyContent="center"
          >
            <FaTwitter
              style={{
                width: "24px",
                height: "24px"
              }}
            />
          </Flex>
        </StyledLink>
      </Flex>

      <Flex
        mb={6}
        pr={[0, 0, 4]}
        flexDirection="column"
        alignItems={["center", "center", "flex-start"]}
        width={[1, 0.5, "calc(100% / 3)"]}
      >
        <StaticImage
          src="../images/gabedmln.png"
          alt="gabedmln"
          style={{
            maxWidth: '200px',
            borderRadius: '999px',
            clipPath: 'circle(50%)'
          }}
        />
        <Text
          mt={5}
          fontSize={[2, 3]}
          fontWeight="bold"
        > gabedmln </Text>
        <Text
          mb={4}
          fontSize={[1, 2]}
          color="white"
        >
          Community Lead
        </Text>
        <StyledLink
          href="https://twitter.com/gabedmln"
          target="_blank"
          title="Twitter"
          rel="noopener noreferrer"
        >
          <Flex
            height="32px"
            width="32px"
            alignItems="center"
            justifyContent="center"
          >
            <FaTwitter
              style={{
                width: "24px",
                height: "24px"
              }}
            />
          </Flex>
        </StyledLink>
      </Flex>

      <Flex
        mb={6}
        pr={[0, 0, 4]}
        flexDirection="column"
        alignItems={["center", "center", "flex-start"]}
        width={[1, 0.5, "calc(100% / 3)"]}
      >
        <StaticImage
          src="../images/justin.png"
          alt="jstn"
          style={{
            maxWidth: '200px',
            borderRadius: '999px',
            clipPath: 'circle(50%)'
          }}
        />
        <Text
          mt={5}
          fontSize={[2, 3]}
          fontWeight="bold"
        > justin </Text>
        <Text
          mb={4}
          fontSize={[1, 2]}
          color="white"
        >
          Community Moderator
        </Text>
        <StyledLink
          href="https://twitter.com/JustinAlvino"
          target="_blank"
          title="Twitter"
          rel="noopener noreferrer"
        >
          <Flex
            height="32px"
            width="32px"
            alignItems="center"
            justifyContent="center"
          >
            <FaTwitter
              style={{
                width: "24px",
                height: "24px"
              }}
            />
          </Flex>
        </StyledLink>
      </Flex>

    </Flex>

  </Container>
</Flex>

export default Team
