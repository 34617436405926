import React from 'react'

// Imports
import '../components/layout.css'
import { Helmet } from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

// Components
import HowItWorks from "../components/HowItWorks"
import Layout from "../components/Layout"
import NavBar from "../components/NavBar"
import Intro from "../components/Intro"
import Rewards from "../components/Rewards"
import Team from "../components/Team"
import FAQs from "../components/FAQs"

const Beta = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>FootFellaz</title>
        <link rel="canonical" href="https://footfellaz.com" />
      </Helmet>
      <Layout>
        <NavBar />
        <Intro />
        <HowItWorks />
        <StaticImage
          src="../images/grid.png"
          alt="Grid view of Fellaz"
          style={{
            maxWidth: '100%',
            minHeight: '320px'
          }} />
        <Rewards />
        <Team />
        <FAQs />
      </Layout>
    </div>
  )
}

export default Beta
