import React from "react"

// Components
import Container from "./Container"
import Flex from "./Flex"
import Heading from "./Heading"
import Text from "./Text"
import RewardsTable from "./RewardsTable"

const Intro = props =>

<Flex
  py={[6, 9]}
  bg="gray2"
  width={1}
  flexDirection="column"
  id="rewards"
>
  <Container>
    <Heading
      as="h2"
      mb={6}
      fontSize={[4, 5]}
      color="white"
    > Rewards </Heading>
    <Text
      mb={6}
      fontSize={[2, 3]}
      color="white"
    >
      Owning a player grants you multiple chances to earn airdropped rewards throughout the season. For example, since there are 3 kits per player and 11 players per club, 33 of you will recieve 1 ETH when your Fellaz' club wins the Champions League.
    </Text>
    <Text
      mb={6}
      fontSize={[2, 3]}
      color="white"
    >
      There will also be monthly rewards given out for Player of the Month across each league. Your Fellaz' number represents the position of a player in real life. Over 75% of the revenue earned from mint will go back towards holders via airdrops throughout the season.
    </Text>
    <Text
      mb={6}
      fontSize={[2, 3]}
      color="white"
    >
      Lastly, should you mint a Legend (11 in total), you will automatically be airdropped 1 ETH.
    </Text>
    <RewardsTable />
  </Container>
</Flex>

export default Intro
