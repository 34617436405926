import React from "react"

// Components
import Box from "./Box"
import Container from "./Container"
import Flex from "./Flex"
import Heading from "./Heading"
import Text from "./Text"
import StyledLink from "./StyledLink"

const Intro = props =>

<Flex
  py={[6, 9]}
  bg="gray2"
  width={1}
  flexDirection="column"
  id="how-it-works"
>
  <Container>
    <Box>
      <Heading
        as="h2"
        mb={6}
        fontSize={[4, 5]}
        color="white"
      > How it works </Heading>
      <Text
        mb={4}
        fontSize={[2, 3]}
        color="white"
      >
        Across Europes top 5 leagues, including ENG, GER, ESP, ITA, and FRA, there are 98 active football clubs. Our collection is made up of a starting XI for every club; each player has 3 kits (Home, Away, and Third).
      </Text>
      <Text
        mb={4}
        fontSize={[2, 3]}
        color="white"
      >
         FootFellaz are 3245 unique digital ERC-721 tokens living on the Ethereum blockchain. They are represented by a derivative NFT of <StyledLink href="https://opensea.io/assets/0x2acab3dea77832c09420663b0e1cb386031ba17b/7498" title="Deadfellaz #7498" target="_blank" rel="noopener noreferrer">Deadfellaz #7498</StyledLink> wearing a custom drawn jersey with a number and club on it. Each number, 1—11, represents a tactical position on the field.
      </Text>
      <Text
        mb={4}
        fontSize={[2, 3]}
        color="white"
      >
        With this project we aim to allow football fans around the globe to show support for their favorite clubs. Owning a Fella will also allow you to recieve performance based rewards for real world leagues, clubs, and players. See the table below to learn more.
      </Text>
      <Text
        mb={4}
        fontSize={[1, 2]}
        color="gray1"
      >
        This project is not affiliated with any real league, club or player.
      </Text>
    </Box>
  </Container>
</Flex>

export default Intro
