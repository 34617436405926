import React from "react"

import Text from "./Text"
import Box from "./Box"

const RewardsTable = props =>

<Box>
  <Box
    bg="gray3"
    style={{
      borderRadius: '8px',
      overflow: 'hidden'
    }}
  >
    <table id="rewards-table">
      <tr>
        <th><Text fontSize={1} fontWeight="bold">Name</Text></th>
        <th><Text fontSize={1} fontWeight="bold">ETH Reward</Text></th>
        <th><Text fontSize={1} fontWeight="bold"># of Players</Text></th>
      </tr>
      <tbody>
        <tr>
          <td><Text fontSize={1}>League Runner-Up</Text></td>
          <td><Text fontSize={1}>0.1 ETH</Text></td>
          <td><Text fontSize={1}>165 players</Text></td>
        </tr>
        <tr>
          <td><Text fontSize={1}>League Winner</Text></td>
          <td><Text fontSize={1}>0.5 ETH</Text></td>
          <td><Text fontSize={1}>165 players</Text></td>
        </tr>
        <tr>
          <td><Text fontSize={1}>Domestic Cup Winner*</Text></td>
          <td><Text fontSize={1}>0.25 ETH</Text></td>
          <td><Text fontSize={1}>165 players</Text></td>
        </tr>
        <tr>
          <td><Text fontSize={1}>Europa League Runner-Up</Text></td>
          <td><Text fontSize={1}>0.1 ETH</Text></td>
          <td><Text fontSize={1}>33 players</Text></td>
        </tr>
        <tr>
          <td><Text fontSize={1}>Europa League Winner</Text></td>
          <td><Text fontSize={1}>0.5 ETH</Text></td>
          <td><Text fontSize={1}>33 players</Text></td>
        </tr>
        <tr>
          <td><Text fontSize={1}>Champions League Runner-Up</Text></td>
          <td><Text fontSize={1}>0.1 ETH</Text></td>
          <td><Text fontSize={1}>33 players</Text></td>
        </tr>
        <tr>
          <td><Text fontSize={1}>Champions League Winner</Text></td>
          <td><Text fontSize={1}>1 ETH</Text></td>
          <td><Text fontSize={1}>33 players</Text></td>
        </tr>
        <tr>
          <td><Text fontSize={1}>Super Cup Winner</Text></td>
          <td><Text fontSize={1}>0.25 ETH</Text></td>
          <td><Text fontSize={1}>33 players</Text></td>
        </tr>
        <tr>
          <td><Text fontSize={1}>League Player of the Month</Text></td>
          <td><Text fontSize={1}>0.25 ETH</Text></td>
          <td><Text fontSize={1}>120 players</Text></td>
        </tr>
        <tr>
          <td><Text fontSize={1}>Legend</Text></td>
          <td><Text fontSize={1}>1 ETH</Text></td>
          <td><Text fontSize={1}>11 players</Text></td>
        </tr>
      </tbody>
    </table>
  </Box>

  <Text
    mt={6}
    fontSize={[1, 2]}
    color="gray1"
  >
    *Domestic cups include FA Cup, DFB Pokal, Copa del Rey, Coupe de France, and Coppa Italia.
  </Text>
</Box>

export default RewardsTable
