import React from "react"

// Components
import Container from "./Container"
import Flex from "./Flex"
import Heading from "./Heading"
import Text from "./Text"

const FAQs = props =>

<Flex
  py={[6, 9]}
  bg="gray2"
  width={1}
  flexDirection="column"
  id="faqs"
>
  <Container>
    <Heading
      as="h2"
      mb={6}
      fontSize={[4, 5]}
      color="white"
    > FAQs </Heading>
    <Text
      mb={6}
      fontSize={[2, 3]}
      color="white"
    >
      Owning a player grants you multiple chances to earn airdropped rewards at the end of the season. For example, if your team wins the Champions League you will recieve 1 ETH as reward. Over 75% of the ETH earned from mint will go towards airdrops for participants. There will also be monthly rewards given out for Player of the Month across each league. Lastly, should you mint a "Legend", you will automatically be rewarded with 1 ETH.
    </Text>
  </Container>
</Flex>

export default FAQs
